import React from 'react'
import { Link } from 'gatsby'

const Card = ({ classes, to, children }) => (
  <div className={`my-4 px-4 w-full md:px-2 lg:px-4 lg:w-1/3 ${classes || ''}`}>
    <div className="overflow-hidden rounded-lg shadow md:hover:shadow-lg shadow-transition">
      {to &&
        <Link to={to} className="text-grey-darker no-underline">
          {children}
        </Link>
      }
      {!to &&
        <>
          {children}
        </>
      }
    </div>
  </div>
)

export default Card
