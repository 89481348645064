import moment from 'moment'

const sessionList = [
]

function displayDates(session) {
  const {startDate, endDate} = session
  const displayFormat = 'M/D'
  const start = moment(startDate).format(displayFormat)
  const end = moment(endDate).format(displayFormat)
  return `${start}-${end}`
}

function sessions() {
  const today = moment.now()

  return sessionList.reduce((result, session) => {
    const { endDate } = session
    const end = moment(endDate)

    if (end.isAfter(today) || end.isSame(today, 'day')) {
      session.displayDates = displayDates(session)
      result.push(session)
    }

    return result
  }, [])
}

export {
  sessions
}
