import React from 'react'
import { sessions } from '../lib/sessions'

const Notes = (notes) => {
  return (
    <div className="mb-2">
      {
        notes.map((note, index) => {
          return <span key={index} className="block text-sm italic">{note}<br/></span>
        })
      }
    </div>
  )
}

const SessionList = ({ showNotes, liClassName }) => {
  const MAX_SESSIONS = 4
  const allSessions = sessions().map((sessionObj, index) => {
    return (
      <li key={index} className={liClassName}>
        {sessionObj.name} &bull; {sessionObj.displayDates}
        {showNotes && sessionObj.notes && Notes(sessionObj.notes)}
      </li>
    )
  })

  return allSessions.splice(0, MAX_SESSIONS)
}

export default SessionList
